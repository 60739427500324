<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { tokenTeacherLogin } from '@/api/local.js'
export default {
  name: 'Login',
  data() {
    return {

    }
  },
  created() {
    const jsbToken = this.$route.query.jsbToken
    if (!jsbToken) {
      this.$router.push({ path: '/' })
    }
    localStorage.setItem('jsbToken', jsbToken)
  },
  components: {

  },
  computed: {
    ...mapGetters(['indexData'])
  },
  mounted() {
    this.tokenTeacherLogin()
  },
  methods: {

    tokenTeacherLogin() {
      tokenTeacherLogin({}).then(res => {
        console.log(res, 'kkiiii')
        if (res.success) {
          const obj = {
            user_name: encodeURI(res.data.name),
            user_id: res.data.id,
            adminType: res.data.adminType,
            number: res.data.jobNum,
            access_token: res.message,
            school_id: res.data.schoolId,
            token: res.data.website_token,
            user_type: 'teacher'
          }
          if (res.data.headImg) {
            obj.head_img = res.data.headImg
          }
          this.setCookie(obj)
          setTimeout(() => {
            this.$router.push({ path: '/' })
          }, 500)
        }
      })
    },
    setCookie(obj) {
      const website_id = this.indexData.websiteId
      const siteDomain = this.indexData.siteDomain
      for (var i in obj) {
        document.cookie = 'AXY_' + website_id + '_' + i + '=' + obj[i] + ';domain=' + siteDomain
      }
    }
  }
}
</script>
